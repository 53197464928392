const DEFAULT_OPTIONS: ScrollIntoViewOptions = {
  block: 'center',
  behavior: 'smooth',
};

export default function scrollToElement(
  el: HTMLElement,
  options: ScrollIntoViewOptions = {},
) {
  el.scrollIntoView({
    ...DEFAULT_OPTIONS,
    ...options,
  });
}
